<template>
  <div>
    <iframe
      width="100%"
      height="1000px"
      :src="
        isFranchiseOrHigher
          ? 'https://lookerstudio.google.com/embed/reporting/f528e578-ded9-447d-b991-86e102c6afe5/page/p_ca91o4pj2c'
          : 'https://lookerstudio.google.com/embed/reporting/14983e22-caec-46d7-bf51-ba5f2d11e410/page/p_ca91o4pj2c'
      "
      frameborder="0"
      style="border: 0"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { isNotCommonUser } from "@/constants/consultants";
import * as accountTypes from "@/modules/account/store/types";
export default {
  computed: {
    ...mapGetters({
      user: accountTypes.USER,
    }),
    isFranchiseOrHigher() {
      return isNotCommonUser(this.user?.user_role_id);
    },
  },
};
</script>
